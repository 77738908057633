import {del, get, patch, post, put} from './http'

/**
 * 获取企业详情
 */
export const getEntDetail = () => get('/tradeapp/tenant', {})

/**
 * 修改企业详情
 */
export const editEntDetail = (params) => patch('/tradeapp/tenant', params)

/**
 * 获取部门列表
 */
export const getDepartmentList = (id) => get('tradeapp/department/list', {
  parentId: id
})

/**
 * 新建部门
 */
export const createDepartment = (params) => post('tradeapp/department', params)

/**
 * 修改部门
 */
export const editDepartment = (id, params) => put('/tradeapp/department/' + id, params)

/**
 * 批量删除部门
 */
export const deleteDepartment = (params) => del('tradeapp/department', params)

/**
 * 获取职位列表
 */
export const getPositionList = (params) => get('tradeapp/position/list', params)

/**
 * 新建职位
 */
export const createPosition = (params) => post('tradeapp/position', params)

/**
 * 修改职位
 */
export const editPosition = (id, params) => put('tradeapp/position/' + id, params)

/**
 * 批量删除职位
 */
export const deletePosition = (params) => del('tradeapp/position', params)

/**
 * 获取成员列表
 */
export const getMemberList = (params) => get('tradeapp/role/list', params)

/**
 * 获取成员详情
 */
export const getMemberDetail = (id) => get('tradeapp/role/ent/' + id)

/**
 * 新建成员
 */
export const createMember = (params) => post('tradeapp/role', params)

/**
 * 编辑成员
 */
export const editMember = (id, params) => put('tradeapp/role/' + id, params)

/**
 * 删除成员
 */
export const deleteMember = (params) => del('tradeapp/role', params)

/**
 * 批量审核成员
 */
export const auditMember = (params) => put('tradeapp/role/review', params)
