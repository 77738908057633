<template>
  <div class="main">
    <div class="title">
      <h2>部门管理</h2>
    </div>
    <div class="content">
      <div class="left">
        <div class="content-title">
          <span>部门</span>
        </div>
        <Tree :data="data"
              :load-data="getDepartmentList"
              :render="renderContent"
              class="demo-tree-render"
              expand-node
              ref="tree"></Tree>
      </div>
      <div class="right">
        <div class="content-title">
          <span>职位</span>
          <div class="content-oper">
            <Button @click.stop="batchDeletePos"
                    v-show="selectArr.length>0">
              <Icon custom="i-icon icon-delete"
                    size="16"/>
              删除
            </Button>
            <Button @click.stop="onClickCreatePos"
                    class="m-l-20"
                    type="primary">
              <Icon custom="i-icon icon-add"
                    size="16"/>
              添加职位
            </Button>
          </div>
        </div>
        <Table :columns="posColumns"
               :data="posData"
               :height="tableHeight"
               @on-selection-change="onSelectionChange"
               highlight-row
               ref="table">
          <template slot="operation"
                    slot-scope="{ row,index }">
            <div class="operation">
              <Icon @click.stop="onClickEditPos(row)"
                    class="m-r-10"
                    custom="i-icon icon-edit"
                    size="18"
                    title="编辑"/>
              <Icon @click.stop="onClickDeletePos(row)"
                    custom="i-icon icon-delete"
                    size="18"
                    title="删除"/>
            </div>
          </template>
        </Table>
        <!--<Page :current="pageNum"-->
        <!--:page-size="pageSize"-->
        <!--:total="total"-->
        <!--@on-change="onPageChange"-->
        <!--@on-page-size-change="onPageSizeChange"-->
        <!--class="text-right m-t-10 m-r-20"-->
        <!--show-elevator-->
        <!--show-sizer-->
        <!--show-total/>-->
      </div>
    </div>
    <Modal :title="posPopupStatus===1?'添加':'编辑'+'职位'"
           footer-hide
           v-model="createPosVisible"
           width="400">
      <div class="createPos">
        <ul>
          <li>
            <span>职位名称：</span>
            <Input style="width:200px"
                   v-model="createPosName"></Input>
          </li>
        </ul>
        <div class="createPos-sub">
          <Button @click.stop="onClickCreateConfirm"
                  class="createPos-btn m-r-20"
                  type="primary">确定
          </Button>
          <Button @click.stop="createPosVisible=false"
                  class="createPos-btn">取消
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  createDepartment,
  createPosition,
  deleteDepartment,
  deletePosition,
  editDepartment,
  editPosition,
  getDepartmentList,
  getPositionList
} from '../../services/enterpriseapi'

export default {
  components: {},
  data () {
    return {
      tableHeight: 0,
      data: [{
        id: 0,
        title: '瓦拉格罗',
        loading: false,
        expand: true,
        children: [],
        render: (h, {root, node, data}) => {
          return h('div', {
            style: {
              width: '100%',
              height: '42px',
              display: 'flex',
              alignItems: 'center'
            },
            on: {
              click: () => {
                this.onClickTreeItem(data)
              }
            }
          }, [
            h('div', {
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            }, [
              h('img', {
                attrs: {
                  src: require('../../assets/common/company_icon_apartment.png')
                },
                style: {
                  width: '16px',
                  height: '16px',
                  marginRight: '8px'
                }
              }),
              h('span', {
                class: 'line1'
              }, data.title)
            ]),
            h('div', {
              style: {
                display: (data.selected && !data.isRename) ? 'flex' : 'none',
                alignItems: 'center',
                marginLeft: '20px'
              }
            }, [
              h('Icon', {
                props: {
                  custom: 'i-icon icon-add',
                  size: '16'
                },
                style: {
                  padding: '5px'
                },
                on: {
                  click: e => {
                    e.stopPropagation()
                    this.onClickAddDepa(data)
                  }
                }
              })
            ])
          ])
        }
      }],
      parentDepa: null,
      isAddStatus: false,
      deptId: 0,
      posData: [],
      posColumns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '职位名称',
          key: 'name',
          minWidth: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属部门',
          key: 'deptName',
          minWidth: 200,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80
        }
      ],
      selectArr: [],
      total: 0,
      pageNum: 1,
      pageSize: 20,
      createPosVisible: false,
      createPosName: '',
      posPopupStatus: 1,// 1新建 2编辑
      posId: 0,
      editPositionData: null
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 294
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 294
      }
      this.getPositionList()
      getDepartmentList(0).then(res => {
        if (res.code === 0) {
          let list = res.data
          if (list.length === 0) {
            return
          }
          let arr = list.map(item => {
            return {
              id: item.id,
              parentId: item.parentId,
              title: item.name,
              newTitle: item.name,
              isRename: false,
              loading: false,
              children: []
            }
          })
          this.data[0].children = arr
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    renderContent (h, {root, node, data}) {
      return h('div', {
        style: {
          width: '100%',
          height: '42px',
          display: 'flex',
          alignItems: 'center'
        },
        on: {
          click: () => {
            this.onClickTreeItem(data)
          }
        }
      }, [
        h('div', {
          style: {
            display: 'flex',
            alignItems: 'center'
          }
        }, [
          h('img', {
            attrs: {
              src: require('../../assets/common/company_icon_apartment.png')
            },
            style: {
              width: '16px',
              height: '16px',
              marginRight: '8px'
            }
          }),
          h('span', {
            class: 'line1',
            style: {
              display: data.isRename ? 'none' : 'block'
            }
          }, data.title),
          h('Input', {
            props: {
              value: data.newTitle
            },
            attrs: {
              id: 'input' + data.id
            },
            style: {
              display: data.isRename ? 'block' : 'none'
            },
            on: {
              'on-blur': (e) => {
                data.newTitle = e.target.value
                this.onBlurDepa(data)
              },
              'on-enter': (e) => {
                e.target.blur()
              },
              'on-focus': (e) => {
                e.stopPropagation()
                e.target.select()
              },
              'click': (e) => {
                e.stopPropagation()
              }
            }
          })
        ]),
        h('div', {
          style: {
            display: (data.selected && !data.isRename) ? 'flex' : 'none',
            alignItems: 'center',
            marginLeft: '20px'
          },
          on: {
            mousedown: e => {
              e.preventDefault()
            }
          }
        }, [
          h('Icon', {
            props: {
              custom: 'i-icon icon-add',
              size: '16'
            },
            style: {
              padding: '5px'
            },
            on: {
              click: e => {
                e.stopPropagation()
                e.preventDefault()
                this.onClickAddDepa(data)
              },
              mousedown: e => {
                console.log(e)
                e.preventDefault()
              }
            }
          }),
          h('Icon', {
            props: {
              custom: 'i-icon icon-edit',
              size: '16'
            },
            style: {
              padding: '5px'
            },
            on: {
              click: e => {
                e.stopPropagation()
                data.isRename = true
                this.$set(data, 'disabled', true)
                this.$nextTick(() => {
                  document.getElementById('input' + data.id).getElementsByTagName('input')[0].focus()
                })
              }
            }
          }),
          h('Icon', {
            props: {
              custom: 'i-icon icon-delete',
              size: '16'
            },
            style: {
              padding: '5px'
            },
            on: {
              click: e => {
                e.stopPropagation()
                console.log(root)
                let parent = root.find(item => {
                  return item.node.id === data.parentId
                })
                console.log(parent)
                this.onClickDeleteDepa(data, parent.node)
              }
            }
          })
        ])
      ])
    },
    // 获取部门列表
    getDepartmentList (item, callback) {
      getDepartmentList(item.id).then(res => {
        if (res.code === 0) {
          let list = res.data
          if (list.length === 0) {
            this.$delete(item, 'children')
            this.$delete(item, 'loading')
            return
          }
          let arr = list.map(item => {
            return {
              id: item.id,
              parentId: item.parentId,
              title: item.name,
              newTitle: item.name,
              isRename: false,
              loading: false,
              children: []
            }
          })
          callback(arr)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击tree item
    onClickTreeItem (item) {
      let arr = this.$refs.tree.getSelectedNodes()
      arr.forEach(items => {
        this.$set(items, 'selected', false)
      })
      this.$set(item, 'selected', true)
      this.deptId = item.id
      this.onListAllSelectCancel()
      this.pageNum = 1
      this.getPositionList()
    },
    // 点击新建部门
    onClickAddDepa (item) {
      if (this.isAddStatus) {
        return
      }
      this.isAddStatus = true
      let newItem = {
        id: -1,
        title: '',
        newTitle: '',
        isRename: true
      }
      if (!item.children) {
        this.$set(item, 'loading', false)
        this.$set(item, 'children', [])
        this.$set(item, 'expand', true)
      }
      if (!item.expand) {
        this.$set(item, 'expand', true)
      }
      item.children.push(newItem)
      this.parentDepa = item
      this.$nextTick(() => {
        document.getElementById('input-1').getElementsByTagName('input')[0].focus()
      })
    },
    // 部门input失焦
    onBlurDepa (item) {
      if (item.id === -1) {
        // 新建
        this.isAddStatus = false
        let parent = this.parentDepa
        parent.children.splice(parent.children.length - 1, 1)
        if (!item.newTitle) {
          if (parent.children.length === 0) {
            this.$delete(parent, 'children')
            this.$delete(parent, 'loading')
            this.$delete(parent, 'expand')
          }
          return
        }
        let params = {
          parentId: parent.id,
          name: item.newTitle
        }
        createDepartment(params).then(res => {
          if (res.code === 0) {
            let data = res.data
            let item = {
              id: data.id,
              parentId: data.parentId,
              title: data.name,
              newTitle: data.name,
              isRename: false
            }
            parent.children.push(item)
          } else {
            if (parent.children.length === 0) {
              this.$delete(parent, 'children')
              this.$delete(parent, 'loading')
              this.$delete(parent, 'expand')
            }
            if (res.msg) this.$Message.info(res.msg)
          }
        }).catch(err => {
          if (parent.children.length === 0) {
            this.$delete(parent, 'children')
            this.$delete(parent, 'loading')
            this.$delete(parent, 'expand')
          }
        })
      } else {
        // 修改
        item.isRename = false
        if (!item.newTitle) {
          item.newTitle = item.title
          return
        }
        if (item.title === item.newTitle) {
          return
        }
        let params = {
          name: item.newTitle,
          parentId: item.parentId
        }
        editDepartment(item.id, params).then(res => {
          if (res.code === 0) {
            item.title = item.newTitle
            item.newTitle = item.newTitle
          } else {
            item.newTitle = item.title
            if (res.msg) this.$Message.info(res.msg)
          }
        }).catch(err => {
          item.newTitle = item.title
        })
      }
    },
    // 删除部门
    onClickDeleteDepa (item, parent) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>确定要删除该部门</p>',
        onOk: () => {
          let params = {
            idList: [item.id].join()
          }
          deleteDepartment(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              let index
              for (const i in parent.children) {
                if (item.id === parent.children[i].id) {
                  index = i
                  break
                }
              }
              parent.children.splice(index, 1)
              if (this.deptId === item.id) {
                this.deptId = 0
                this.getPositionList()
              }
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 全选取消
    onListAllSelectCancel () {
      this.selectArr = []
    },
    // 获取职位列表
    getPositionList () {
      let params = {
        departmentId: this.deptId,
      }
      getPositionList(params).then(res => {
        if (res.code === 0) {
          this.posData = res.data.map(child => {
            let departmentId = 0
            let deptName = ''
            if (child.department) {
              deptName = child.department.name
              departmentId = child.department.id
            }
            child.deptName = deptName
            child.departmentId = departmentId
            return child
          })
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 页数改变
    onPageChange (event) {
      this.onListAllSelectCancel()
      this.pageNum = event
      this.getPositionList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.onListAllSelectCancel()
      this.pageSize = event
      this.getPositionList()
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // 点击添加职位
    onClickCreatePos () {
      if (!this.deptId) return this.$Message.info('请先选择部门')
      this.createPosName = ''
      this.posPopupStatus = 1
      this.createPosVisible = true
    },
    onClickEditPos (row) {
      this.editPositionData = row
      this.posId = row.id
      this.createPosName = row.name
      this.posPopupStatus = 2
      this.createPosVisible = true
    },
    // 新建/编辑职位
    onClickCreateConfirm () {
      if (!this.createPosName) return this.$Message.info('请输入职位名称')
      let params = {
        departmentId: this.deptId,
        name: this.createPosName
      }
      if (this.posPopupStatus === 1) {
        if (this.deptId < 1) return this.$Message.info('请选择部门')
        createPosition(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('添加成功')
            this.createPosVisible = false
            this.onListAllSelectCancel()
            this.getPositionList()
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        params.departmentId = this.editPositionData.departmentId
        editPosition(this.posId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.createPosVisible = false
            this.onListAllSelectCancel()
            this.getPositionList()
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击删除职位
    onClickDeletePos (row) {
      this.onDeletePos([row])
    },
    // 批量删除职位
    batchDeletePos () {
      this.onDeletePos(this.selectArr)
    },
    // 删除职位
    onDeletePos (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>确定要删除该职位</p>',
        onOk: () => {
          let idList = list.map(item => item.id)
          let params = {
            idList: idList.join()
          }
          deletePosition(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.onListAllSelectCancel()
              this.getPositionList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;

    .title {
      height: 70px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8d8d8;

      h2 {
        font-size: 20px;
      }
    }

    .content {
      height: 100%;
      padding: 20px;
      display: flex;

      .content-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 16px;
        border-bottom: 1px solid #e3e7ee;
      }

      .left,
      .right {
        height: 100%;
        overflow: auto;
        border: 1px solid #e3e7ee;
      }

      .left {
        width: 320px;
        margin-right: 20px;

        ::v-deep .ivu-tree ul li {
          margin: 0;
          padding-left: 10px;

          .ivu-tree-arrow {
            line-height: 42px;
          }
        }
      }

      .right {
        flex: 1;

        ::v-deep .ivu-table-cell {
          padding: 0;
        }

        .operation {
          display: flex;
          align-items: center;

          ::v-deep .ivu-icon {
            cursor: pointer;
          }
        }
      }
    }
  }

  .createPos {
    padding: 20px 30px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      span {
        width: 70px;
        font-size: 14px;
      }
    }

    .createPos-sub {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .createPos-btn {
        width: 80px;
      }
    }
  }
</style>
